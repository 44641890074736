import {
    disableBodyScroll as originalDisableBodyScroll,
    enableBodyScroll as originalEnableBodyScroll,
    clearAllBodyScrollLocks as originalClearAllBodyScrollLocks
} from 'body-scroll-lock-upgrade';

const delay = 250;

let timeout = null;

const debouncedCall = (delay = 0, callback = () => {}, ...params) => {
    if (timeout) {
        clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
        clearTimeout(timeout);

        callback(...params);
    }, delay);
};

export const disableBodyScroll = (...params) => {
    document.body.classList.add('overflow-hidden');

    originalDisableBodyScroll(...params);

    document.dispatchEvent(new Event('scroll:disabled'));
};

export const clearAllBodyScrollLocks = (...params) => {
    document.body.classList.remove('overflow-hidden');

    originalClearAllBodyScrollLocks(...params);

    document.dispatchEvent(new Event('scroll:enabled'));
};

export const enableBodyScroll = (...params) => {
    originalEnableBodyScroll(...params);
    clearAllBodyScrollLocks();

    document.dispatchEvent(new Event('scroll:enabled'));
};

export const debouncedDisableBodyScroll = (...params) => debouncedCall(delay, disableBodyScroll, ...params);

export const debouncedEnableBodyScroll = (...params) => {
    debouncedCall(delay, enableBodyScroll, ...params);
};

export const debouncedClearAllBodyScrollLocks = (...params) => {
    debouncedCall(delay, clearAllBodyScrollLocks, ...params);
};

export default {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
    debouncedDisableBodyScroll,
    debouncedEnableBodyScroll,
    debouncedClearAllBodyScrollLocks
};
